export const ShopContentEn = [
    {
        title: "Unique souvenirs from our mine",
        description:
            "At the reception of the mine you can see and buy unique souvenirs brought from the mine excavation fields. These are mainly galena, limonite, hematite and barite unique souvenirs that are nicely packaged and great for a symbolic gift.",
        images_directory: "spominki",
    },
    {
        title: "Pendant with Hematite",
        description:
            "Hematite is a crystal that strengthens our bond with the earth and gives us a sense of security and homeliness. It works by balancing the energy of the body, mind, and spirit. Hematite cleanses and protects the aura, grounding, dispels negative energies, eliminates fears, reduces daily tension, and improves blood flow. However, in treatment, it absorbs pain from the body at the site where it is placed.",
        images_directory: "hematitni_nakit",
    },
    {
        title: "The book In the Dark Depths of Sitarjevec",
        description:
            "The book, which contains 6 short stories, was written by Darinka Kobal. The book was illustrated by Anita Vozelj, according to the storytellers.",
        images_directory: "knjiga_v_temnih_globinah_sitarjevca",
    },
    {
        title: "The book of Old Court",
        description:
            "The book, which contains 4 stories, was written by Darinka Kobal. Anita Vozelj provided excellent illustrations.",
        images_directory: "knjiga_stara_sodnija",
    },
    {
        title: "The book When Litija was not Litija yet",
        description:
            "In the book, the writer Darinka Kobal describes where the natives once settled, how and with what they lived and in what kind of homes they lived in. Anita Vozelj supported the writer's text with beautiful illustrations.",
        images_directory: "knjiga_ko_litija_se_ni_bila_litija",
    },
    {
        title: "Backpack Litko",
        description:
            "Litko is the miner and mascot of the Litija Carnival. He delights visitors at all major events in Litija, and lives in the Sitarjevec mine, for most of the time he hides in its depths. Its image is also adorned with a convenient backpack made of impermeable material.",
        images_directory: "nahrbtnik_litko",
    },
    {
        title: "Pencil Rudnik Sitarjevec",
        images_directory: "svincnik_rudnik_sitarjevec",
        description:
           "Useful souvenir - a black pencil with the name and logo of the mine.",
    },
    {
        title: "Black socks Rudnik Sitarjevec",
        description:
            "Useful souvenir - comfortable black socks with the name and logo of the mine.",
        additional_text: "Material: 90% combed cotton and 10% elastane.",
        images_directory: "nogavice",
    },
    {
        title: "Postcards with a mine motif",
        description:
            "Useful souvenir - 2 types of postcards with the name and logo of the mine. The brand is also included.",
        images_directory: "razglednice",
    },
    {
        title: "Lead castings",
        description:
            "The reception of the mine offers a wide range of products made of lead castings. In the days around the New Year, divination by pouring molten lead (or wax) into cold water has been common in Slovenia since the middle of the 20th century. This tradition is part of the rich Slovenian tradition, customs and habits that originate from pagan religious and secular rites. They usually cast three castings, for the past, present, and future from which they then prophesied.",
        images_directory: "svinceni_ulitki",
    },
    {
        title: "Ceramic products with mine motifs",
        description:
            "A wide range of different ceramic cups and mugs with motifs from the Sitarjevec mine is available for purchase.",
        images_directory: "keramicni_izdelki",
    },
    {
        title: "A small wallet and arrow in shades of okra mining pigment",
        description:
            "A small wallet and an arrow in shades of okra and limonite mine pigment is a great gift and a memory of visiting a mine.",
        images_directory: "drobiznica_in_puscica",
    },
    {
        title: "Mine crayons",
        description: "Cute set of 6 crayons with a spike and a mine logo.",
        images_directory: "barvice",
    },

    {
        title: "Toy bat made of textile",
        description:
            "For our little ones you can buy a cute toy in the shape of a bat at the reception. It is also suitable for head support on longer trips.",
        images_directory: "igraca_netopir",
    },
    {
        title: "Bat key ring",
        description:
            "The original gift from our mine are key rings in the shape of a bat.",
        images_directory: "obesek_netopir",
    },
    {
        title: "Book Fossils of Slovenia",
        description:
            "The book Fossils of Slovenia by dr. Bogdan Jurkovšek and dr. Tee Kolar-Jurkovšek presents a walk through geological periods with the help of macrofossils found in Slovenia, recognizing the way of life of former organisms, their communities and the environment in which they lived and the conditions at their death and subsequent fossilization. It presents some of the key geological events, from local and regional to global, that have navigated the evolutionary pathways of the living world to the present day.",
        images_directory: "knjiga_fosili_slovenije",
    },
    {
        title: "Enamelled cups of the Sitarjevec mine",
        description:
            "Available for purchase are nice enameled cups with various motifs, reminiscent of the Sitarjevec Litija Mine.",
        images_directory: "loncki",
    },
    {
        title: "Black / white mine shirt",
        description:
            "Black or white T-shirt with a map of the tunnels and the mine logo for all age groups.\n",
        images_directory: "majice",
        additional_text: "Material: 100% cotton",
    },
    {
        title: "Pen Rudnik Sitarjevec",
        description:
            "Useful souvenir - black ballpoint pen with the name and logo of the mine.",
        images_directory: "kemicni_svincnik",
    },
    {
        title: "Unique greeting card",
        description:
            "Handmade greeting card made of natural materials is an ideal gift for special occasions",
        images_directory: "unikatna_voscilnica",
    },
    {
        title: "Štajger - the herbal tea of the miners",
        description: "Štajger's herbal tea contains a mixture of herbs that were once drunk by miners. The selection of herbs has a beneficial effect on the respiratory system and lungs.",
        images_directory: "stajgerjev_caj"
    },
    {
        title: "Ear band",
        description: "Ear band with a mining motive.",
        images_directory: "trak"
    },
    {
        title: "Herbal Gift Pack",
        description: "Various gift packages are prepared at Pr’ Janez Farm. They contain tea for well-being, vegetable addition to meals or vinegar with vegetables.",
        images_directory: "darilni_paket"
    },
    {
        title: "Honey",
        description: "Various types of honey from the Dremelj Beekeeping are available for purchase.",
        images_directory: "med"
    },
    {
        title: "Eco dried fruit",
        description: "Eco dried fruit - fruit bars that are a healthy substitute for desserts.",
        images_directory: "suho_sadje"
    },
    {
        title: "Mining elf Sitarjevček",
        description: "The gnome is made of combed sheep's wool using the dry felting technique (with a special needle). He is characterized by a red cap, beard and mustache are black-grey-white (dirty from dust and mud).",
        images_directory: "rudarski_skratek"
    },
    {
        title: "The hidden stone",
        description: "When wrapping the stone in felt, wool from the Vovše Eco Farm is used, and the stones are obtained from the Sitarjevec Litija mine.",
        images_directory: "skriti_kamen"
    }
];
