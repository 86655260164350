import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

function ShopHeader(props) {

    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(relativePath: { eq: "rudnik/prikaz-izdelkov-in-trgovina.jpg" }) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    const imageData = data.desktop.childImageSharp.fluid;

    return (
        <div className="slider-activation im_modern_slider" data-black-overlay="9" style={{minHeight: "33vh"}}>
            <BackgroundImage Tag="div" className="rn-page-title-area pt--120 pb--190 bg_image bg_image--27" fluid={imageData} backgroundColor={`#040e18`} data-black-overlay="7">
                <div className="container">
                    <div className="slide-style-2 d-flex align-items-center justify-content-center">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h2 className="page-heading text-white">
                                    Souvenirs from the Sitarjevec Mine
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        </div>
    );
}

export default ShopHeader;