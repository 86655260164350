import React from 'react';
import { ShopContentEn } from "./shop-content";
import ShopElement from "./ShopElement";

function ShopItems({column, images}) {
    return (
        <>
            {ShopContentEn.map((shopElement , index ) => (
                <ShopElement
                    title={shopElement.title}
                    key={'shop-element' + index }
                    description={shopElement.description}
                    slug={shopElement .images_directory.replace(/_/g, '-')}
                    column={column}
                    imagesData={images[shopElement.images_directory]}
                />
            ))}
        </>
    );
}

export default ShopItems;