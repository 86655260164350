import React from 'react';
import ShopItems from "./ShopItems";

function ShopDescription({images}) {
    return (
        <>
            <div className="about-wrapper ptb--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center d-flex">
                        <div className="col-12 col-lg-8 mx-auto">
                            <div className="section-title text-center">
                                <h2 className="title">Shop with products</h2>
                                <p className="description">
                                    At the reception of our mine you can buy various souvenirs - several types of memory magnets, postcards, socks, cute cups, souvenirs for children such as a toy bat, a bat pendant, crayons, change, arrows. We also sell hematite jewelry, souvenirs from various ores and minerals, including hematite-barite, limonite and galena souvenirs from the Sitarjevec Litija mine. Various books are also available for purchase: In the Dark Depths of Sitarjevec, Stara sodnija, Ko Litija nije bila Litija and Fosili Slovenije.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <ShopItems images={images} column="col-lg-4 col-md-6 col-sm-6 col-12" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShopDescription;