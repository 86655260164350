import React, { useEffect, useState } from "react";
import ScrollSpy from "react-scrollspy";
import { Link } from "gatsby";
import { FiMenu, FiX } from "react-icons/fi";
import {StaticImage} from "gatsby-plugin-image";

const Header = () => {
    useEffect(() => {
        window.addEventListener("scroll", function () {
            let value = window.scrollY;
            if (value > 50) {
                document
                    .querySelector(".header--fixed")
                    .classList.add("sticky");
            } else {
                document
                    .querySelector(".header--fixed")
                    .classList.remove("sticky");
            }
        });

        let elements = document.querySelectorAll(".has-droupdown > a");

        for (let i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement
                        .querySelector(".submenu")
                        .classList.toggle("active");
                    this.classList.toggle("open");
                };
            }
        }
    });

    const menuTrigger = () => {
        document.querySelector(".header-wrapper").classList.toggle("menu-open");
    };

    const closeMenuTrigger = () => {
        document.querySelector(".header-wrapper").classList.remove("menu-open");
    };

    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

    const dropdownRef = React.createRef();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsSubmenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const handleDropdownClick = (e) => {
        if (!isSubmenuOpen) {
            e.preventDefault();
            setIsSubmenuOpen(true);
        }
    };

    return (
        <header className="header-area formobile-menu header--fixed default-color">
            <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">
                    <div className="logo">
                        <Link to="/en"></Link>
                    </div>
                </div>
                <div className="header-right">
                    <nav className="mainmenunav d-lg-block">
                        <ScrollSpy
                            className="mainmenu"
                            items={[]}
                            currentClassName="is-current"
                            offset={-200}
                        >
                            <li>
                                <Link to="/en">Home Page</Link>
                            </li>

                            <li className="has-droupdown" ref={dropdownRef}>
                                <Link to="/obisci-rudnik/glavni-rov" className="" onClick={handleDropdownClick}>Visit the Mine</Link>
                                <ul className={`submenu ${isSubmenuOpen ? 'active' : ''}`}>
                                    <li>
                                        <Link to="/en/visit-the-mine/the-main-pit">
                                            Main Pit
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/en/visit-the-mine/the-export-pit">
                                            Export Pit
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/en/visit-the-mine/animated-tours">
                                            Animated Tours
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/en/visit-the-mine/culinary">
                                            Culinary
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/en/visit-the-mine/events">
                                            Events
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/en/visit-the-mine/shop-and-souvenirs">
                                            Shop and Souvenirs
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/en/explore-the-mine">
                                    Explore the Mine
                                </Link>
                            </li>
                            <li>
                                <Link to="/en/price-list">Price List</Link>
                            </li>
                            <li>
                                <Link to="/en/contact-and-information">
                                    Contact and Information
                                </Link>
                            </li>

                            <li>
                                <Link to={"/"} className="country-flag">
                                    <StaticImage
                                        src="../../../assets/images/rudnik/si.png"
                                        alt="Slovenian pages"
                                        placeholder="blurred"
                                        imgClassName="img-fluid w-100"
                                        loading="lazy"
                                    />
                                </Link>
                            </li>
                        </ScrollSpy>
                    </nav>
                    {/* Start Humberger Menu  */}
                    <div className="humberger-menu d-block d-lg-none pl--20">
                        <span
                            onClick={menuTrigger}
                            className="menutrigger text-white"
                        >
                            <FiMenu />
                        </span>
                    </div>
                    {/* End Humberger Menu  */}
                    <div className="close-menu d-block d-lg-none">
                        <span
                            onClick={closeMenuTrigger}
                            className="closeTrigger"
                        >
                            <FiX />
                        </span>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
